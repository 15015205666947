import { useContext } from 'react'
import { MessageContextValue, MessageContext } from '../contexts/message'

export const useMessage = (): MessageContextValue => {
  const {
    message,
    addMessage,
    handleAPIError
  } = useContext(MessageContext)

  return {
    message,
    addMessage,
    handleAPIError
  }
}
