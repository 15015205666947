import React from 'react'
import { useAuth } from '../../hooks/useAuth'
import { SuperAdminRoleBadge } from '../RoleBadge/SuperAdmin.RoleBadge'
import { RoundedIcon } from '../RoundedIcon'

export const SidebarUser = (): JSX.Element => {
  const { loggedInUser } = useAuth()

  return (
    <div className='flex flex-row items-center space-x-4 overflow-hidden'>
      <div>
        <RoundedIcon colorful={false}>
          <div>{loggedInUser !== null && loggedInUser?.email?.length > 0 ? loggedInUser?.email[0]?.toUpperCase() : 'nop'}</div>
        </RoundedIcon>
      </div>
      <div className='flex flex-col font-bold space-y-1'>
        {loggedInUser?.isSuperAdmin as boolean && (
          <div>
            <SuperAdminRoleBadge />
          </div>
        )}
        <div>
          <span>{loggedInUser?.email}</span>
        </div>
      </div>
    </div>
  )
}
