/* eslint-disable @typescript-eslint/promise-function-async */

import { map as temap, TaskEither, tryCatch } from 'fp-ts/TaskEither'
import { APIError } from './APIError'
import { getEmail, parseUser, UnparsedUser, User } from '../domain/user'
import { App } from '../domain/app'
import { Role } from '../domain/role'
import { getAuthHeader, getServerUrl, toAPIError } from './utils'
import { axiosInstance, axiosPromise } from './axios'
import { pipe } from 'fp-ts/function'
import { map as amap } from 'fp-ts/Array'

export const fetchUsers: TaskEither<APIError, User[]> = pipe(
  tryCatch(
    () => axiosPromise<UnparsedUser[]>(axiosInstance.get(
      `${getServerUrl()}/user/`,
      { headers: { ...getAuthHeader() } }
    )),
    toAPIError
  ),
  temap(amap(parseUser))
)

export const fetchUser = (email: string): TaskEither<APIError, User> => pipe(
  tryCatch(
    () => axiosPromise<UnparsedUser>(axiosInstance.get(
      `${getServerUrl()}/user/${email}`,
      {
        headers: { ...getAuthHeader() }
      }
    )),
    toAPIError
  ),
  temap(parseUser)
)

export const createUser = (email: string): TaskEither<APIError, User> => pipe(
  tryCatch(
    () => axiosPromise<UnparsedUser>(axiosInstance.post(
      `${getServerUrl()}/user/`,
      { email },
      { headers: { ...getAuthHeader() } }
    )),
    toAPIError
  ),
  temap(parseUser)
)

export const removeUser = (email: string): TaskEither<APIError, void> => pipe(
  tryCatch(
    () => axiosPromise(axiosInstance.delete(
      `${getServerUrl()}/user`,
      {
        data: { email },
        headers: { ...getAuthHeader() }
      }
    )),
    toAPIError
  )
)

export const addRole = (
  email: string,
  role: Role
): TaskEither<APIError, User> => pipe(
  tryCatch(
    () => axiosPromise<UnparsedUser>(axiosInstance.post(
      `${getServerUrl()}/user/role`,
      {
        email,
        role
      },
      { headers: { ...getAuthHeader() } }
    )),
    toAPIError
  ),
  temap(parseUser)
)

export const removeRole = (
  email: string,
  role: Role
): TaskEither<APIError, User> => pipe(
  tryCatch(
    () => axiosPromise<UnparsedUser>(axiosInstance.delete(
      `${getServerUrl()}/user/role`,
      {
        data: {
          email,
          role
        },
        headers: { ...getAuthHeader() }
      }
    )),
    toAPIError
  ),
  temap(parseUser)
)

export const updateRoles = (
  user: User,
  app: App,
  newRoles: Record<string, Role>
): TaskEither<APIError, User> => pipe(
  tryCatch(
    () => axiosPromise<UnparsedUser>(axiosInstance.put(
      `${getServerUrl()}/user/role`,
      {
        email: getEmail(user),
        app,
        newRoles
      },
      { headers: { ...getAuthHeader() } }
    )),
    toAPIError
  ),
  temap(parseUser)
)
