/* eslint-disable @typescript-eslint/no-floating-promises */

import React, {
  FunctionComponent,
  createContext,
  useState,
  useEffect
} from 'react'
import { useAuth } from '../hooks/useAuth'
import { fold as efold } from 'fp-ts/Either'
import { append, unsafeUpdateAt, unsafeDeleteAt } from 'fp-ts/Array'
import { fetchUsers, createUser, removeUser, updateRoles, addRole } from '../services/user'
import { User } from '../domain/user'
import { App } from '../domain/app'
import { Role } from '../domain/role'
import { useMessage } from '../hooks/useMessage'

export interface UsersContextValues {
  users: User[]
  handleAddUser: (email: string) => void
  handleDeleteUser: (user: User) => void
  handleAddRole: (user: User, role: Role) => void
  handleUpdateRoles: (user: User, app: App, newRoles: Record<string, Role>) => void
}

export const UsersContext = createContext<UsersContextValues>({
  users: [],
  handleAddUser: () => {},
  handleDeleteUser: () => {},
  handleAddRole: () => {},
  handleUpdateRoles: () => {}
})

export const UsersContextProvider: FunctionComponent = ({ children }) => {
  const { loggedInUser } = useAuth()
  const { handleAPIError } = useMessage()
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    if (loggedInUser !== null) {
      fetchUsers()
        .then(efold(
          handleAPIError,
          setUsers
        ))
    }
  }, [loggedInUser])

  const handleAddUser = (email: string): void => {
    createUser(email)()
      .then(efold(
        handleAPIError,
        (newUser) => {
          setUsers(append(newUser))
        }
      ))
  }

  const handleDeleteUser = (user: User): void => {
    removeUser(user.email)()
      .then(efold(
        handleAPIError,
        () => {
          const index = users.findIndex(({ email }) => user.email === email)
          setUsers((users) => unsafeDeleteAt(index, users))
        }
      ))
  }

  const handleAddRole = (user: User, role: Role): void => {
    addRole(user.email, role)()
      .then(efold(
        handleAPIError,
        (newUser) => {
          const index = users.findIndex((user) => user.email === newUser.email)
          setUsers((users) => unsafeUpdateAt(index, newUser, users))
        }
      ))
  }

  const handleUpdateRoles = (user: User, app: App, newRoles: Record<string, Role>): void => {
    updateRoles(user, app, newRoles)()
      .then(efold(
        handleAPIError,
        (newUser) => {
          const index = users.findIndex((user) => user.email === newUser.email)
          setUsers((users) => unsafeUpdateAt(index, newUser, users))
        }
      ))
  }

  const contextValue: UsersContextValues = {
    users,
    handleAddUser,
    handleDeleteUser,
    handleAddRole,
    handleUpdateRoles
  }

  return (
    <UsersContext.Provider value={contextValue}>
      {children}
    </UsersContext.Provider>
  )
}
