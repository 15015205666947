import React from 'react'
import { SidebarUser } from './SidebarUser'
import { SidebarSelection } from './SidebarSelection'
import { Button } from '../Button'
import { useAuth } from '../../hooks/useAuth'

export const Sidebar = (): JSX.Element => {
  const { tryLogout } = useAuth()

  return (
    <div className='flex flex-col w-80 p-12 border-r border-gray-200'>
      <div className='flex flex-col space-y-12'>
        <h1 className='font-bold text-3xl'>iros-users</h1>
        <div>
          <SidebarUser />
        </div>
        <div>
          <SidebarSelection />
        </div>
      </div>

      <Button
        classes='mt-auto transition-colors hover:bg-red-500 hover:text-white'
        onClick={tryLogout}
      >
        Log-out
      </Button>
    </div>
  )
}
