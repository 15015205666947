import { useContext } from 'react'
import { AuthContextValue, AuthContext } from '../contexts/auth'

export const useAuth = (): AuthContextValue => {
  const {
    loggedInUser,
    tryLogin,
    tryLogout
  } = useContext(AuthContext)

  return {
    loggedInUser,
    tryLogin,
    tryLogout
  }
}
