/* eslint-disable @typescript-eslint/no-floating-promises */

import { Suspense, lazy, useState, useEffect } from 'react'
import Loading from './pages/loading'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { ProtectedRouter } from './components/ProtectedRouter'
import { MobileHeader } from '../src/components/MobileHeader'
import { MessageContainer } from './components/MessageContainer'
import { App } from './domain/app'
import { useMessage } from './hooks/useMessage'
import { useAuth } from './hooks/useAuth'
import { fetchApps } from './services/apps'
import { Sidebar } from './components/Sidebar/Sidebar'
import { fold } from 'fp-ts/Either'

const Login = lazy(async () => await import('./pages/login'))
const AppContainer = lazy(async () => await import('./pages/app'))
const RequestPasswordReset = lazy(async () => await import('./pages/requestPasswordReset'))
const ResetPassword = lazy(async () => await import('./pages/resetPassword'))
const Users = lazy(async () => await import('./pages/users'))
const UserPage = lazy(async () => await import('./pages/user'))
const NotFound = lazy(async () => await import('./pages/notFound'))
const Apps = lazy(async () => await import('./pages/apps'))

export default function Home (): JSX.Element {
  const [apps, setApps] = useState<App[]>([])
  const { loggedInUser } = useAuth()
  const { addMessage, handleAPIError } = useMessage()
  const history = useHistory()

  useEffect(() => {
    if (loggedInUser !== null) {
      addMessage({
        content: `Hey ${loggedInUser.email}, you are now logged in.`,
        type: 'SUCCESS'
      })

      fetchApps()
        .then(fold(
          handleAPIError,
          setApps
        ))

      history.push('/apps')
    }
  }, [loggedInUser])

  return (
    <div className='flex flex-col md:flex-row w-full h-full'>
      {loggedInUser !== null && (
        <div className='hidden md:flex'>
          <Sidebar />
        </div>
      )}

      <div className='md:hidden'>
        <MobileHeader />
      </div>

      <div className='flex-1 h-full'>
        <div className='h-full'>
          <Suspense fallback={Loading}>
            <Switch>
              <Route exact path='/'>
                {loggedInUser !== null
                  ? <Redirect to='/apps' />
                  : <Redirect to='/login' />
                }
              </Route>

              <Route exact path='/login'>
                <Login />
              </Route>

              <ProtectedRouter
                exact
                path='/app/:name'
              >
                <AppContainer
                  apps={apps}
                />
              </ProtectedRouter>

              <ProtectedRouter
                exact
                path='/apps'
              >
                <Apps
                  apps={apps}
                />
              </ProtectedRouter>

              <ProtectedRouter
                exact
                path='/users'
              >
                <Users
                  apps={apps}
                />
              </ProtectedRouter>

              <ProtectedRouter
                path='/users/:email'
              >
                <UserPage apps={apps} />
              </ProtectedRouter>

              <Route path='/request-password-reset'>
                <RequestPasswordReset />
              </Route>

              <Route path='/reset-password'>
                <ResetPassword />
              </Route>

              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </div>

        <MessageContainer />
      </div>
    </div>
  )
}
