import axios, { AxiosResponse } from 'axios'
import { APIError } from './APIError'

export const handleAxiosResponse = <T>(res: AxiosResponse): T => {
  if (res.status >= 200 && res.status <= 300) {
    return res.data
  }

  const error = res.data as APIError
  throw new Error(error.message)
}

export const axiosPromise = async <T>(promise: Promise<AxiosResponse<T>>): Promise<T> => {
  return await promise.then((res) => handleAxiosResponse<T>(res))
}

export const axiosInstance = axios.create({
  validateStatus: (status) => status !== 500
})
