import React from 'react'
import { Link } from 'react-router-dom'
import { SidebarSelectionRow } from './SidebarSelectionRow'
import { ReactComponent as AppsIcon } from '../../assets/icons/app-indicator.svg'
import { ReactComponent as PeopleIcon } from '../../assets/icons/people.svg'

export const SidebarSelection = (): JSX.Element => {
  return (
    <div className='flex flex-col space-y-2'>
      <Link to='/apps'>
        <SidebarSelectionRow
          name='Apps'
          icon={<AppsIcon className='w-6 h-6' />}
        />
      </Link>
      <Link to='/users'>
        <SidebarSelectionRow
          name='Users'
          icon={<PeopleIcon className='w-6 h-6' />}
        />
      </Link>
    </div>
  )
}
