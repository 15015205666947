import React from 'react'
import { Transition } from '@headlessui/react'
import { Message } from '../domain/message'
import { useMessage } from '../hooks/useMessage'
import { ReactComponent as ErrorIcon } from '../assets/icons/exclamation-circle-fill.svg'
import { ReactComponent as SuccessIcon } from '../assets/icons/check-circle-fill.svg'
import classnames from 'classnames'

interface MessageCardProps {
  message: Message
}

const MessageCard = ({ message: { content, type } }: MessageCardProps): JSX.Element => {
  return (
    <div
      className={classnames('p-4 flex flex-row space-x-4 items-center border border-gray-200 rounded-md bg-green-100', {
        'bg-green-100': type === 'SUCCESS',
        'bg-red-100': type === 'ERROR'
      })}
    >
      <div className='flex flex-row space-x-4 items-center'>
        <div className={classnames('w-1 rounded-md h-12 block', {
          'bg-red-400': type === 'ERROR',
          'bg-green-400': type === 'SUCCESS'
        })} />

        {type === 'ERROR' && <ErrorIcon className='text-red-400 w-6 h-6' />}
        {type === 'SUCCESS' && <SuccessIcon className='text-green-400 w-6 h-6' />}
      </div>

      <div className='flex flex-col'>
        <div className='font-lg font-bold'>
          {type === 'ERROR' ? 'Oops, something went wrong.' : 'Success!'}
        </div>
        <div className='text-sm text-gray-600'>
          {content}
        </div>
      </div>
    </div>
  )
}

export const MessageContainer = (): JSX.Element => {
  const { message } = useMessage()

  return (
    <div className='fixed flex justify-center bottom-4 md:right-4 w-full md:w-auto px-4 z-50'>
      {message !== null && (
        <Transition
          appear={true}
          show={true}
          enter='transition-opacity duration-200'
          enterFrom='opacity-0'
          enterTo='opacity-100'
        >
          <MessageCard message={message} />
        </Transition>
      )}
    </div>
  )
}
