/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { App } from './app'
import { Section } from './section'
import { Role, RolePermission, SectionRole } from './role'
import { Auth } from './auth'
import { Modify } from '../utils/types'
import { flow } from 'fp-ts/function'
import { flip } from 'fp-ts-std/Function'
import { isSome, Option } from 'fp-ts/Option'
import { foldMap, sort } from 'fp-ts/Array'
import { Ord as StringOrd } from 'fp-ts/string'
import { stringSeparatorMonoid } from '../utils/string'
import { Ord as DateOrd } from 'fp-ts/Date'
import { Ord, contramap } from 'fp-ts/Ord'
import { prop } from 'fp-ts-ramda'
import { lookup, some } from 'fp-ts/Record'

/*
 *  Interfaces
 */
export interface UnparsedUser {
  email: string
  isSuperAdmin: boolean
  roles: Record<string, Role>
  auth: Auth[]
  createdAt: string
}

export type User = Modify<UnparsedUser, {
  createdAt: Date
}>

/*
 * Getters
 */
export const getEmail = ({ email }: User): string => email

export const getName = ({ email }: User): string =>
  email
    .split('@')[0]
    .split('.')
    .join(' ')

export const getCreationDate = ({ createdAt }: User): Date => createdAt

export const getRoles = ({ roles }: User): Record<string, Role> => roles

export const getRoleForApp = (app: App) => (user: User): Option<Role> => lookup(app.name)(user.roles)

export const unsafeGetRoleForApp = (app: App) => (user: User): Role => user.roles[`${app.name}`]

export const unsafeGetSectionFromRole = (section: Section) => (role: Role): SectionRole => role.sections[`${section.name}`]

export const getSectionFromRole = (section: Section) => (role: Role): Option<SectionRole> => lookup(section.name)(role.sections)

/*
 * Typeclass instances
 */
export const ordByAlpha: Ord<User> = contramap(getName)(StringOrd)
export const ordByDate: Ord<User> = contramap(getCreationDate)(DateOrd)

/*
 * Functions
 */
export const parseUser = (u: UnparsedUser): User => ({
  ...u,
  createdAt: new Date(u.createdAt)
})

export const hasApp = (app: App) => (user: User): boolean => user.roles[`${app.name}`] !== undefined

export const hasPermission = (permission: RolePermission) => (app: App) => (user: User): boolean => {
  const role = getRoleForApp(app)(user)

  if (user.isSuperAdmin) {
    return true
  }

  if (isSome(role)) {
    return role.value[permission]
  }

  return false
}

export const canManageUsersOfApp = hasPermission('canManageUsers')

export const isOverallUserOfApp = hasPermission('isOverallUser')

export const isOverallAdminOfApp = hasPermission('isOverallAdmin')

export const isOverallAdminOfSomeApp: (user: User) => boolean = flow(
  getRoles,
  some(prop('isOverallAdmin'))
)

export const canManageUsersOfSomeApp: (user: User) => boolean = flow(
  getRoles,
  some(prop('canManageUsers'))
)

export const sortByAlpha = sort(ordByAlpha)

export const sortByNew = sort(ordByDate)

export const showSections: (sections: SectionRole[]) => string = foldMap(stringSeparatorMonoid)(({ name, role }) => `${name}: ${role}`)

/*
 * Flipped variations
 */
export const flippedCanManageUsersOfApp = flip(canManageUsersOfApp)
export const flippedHasApp = flip(hasApp)
