import React, {
  FunctionComponent,
  createContext,
  useState,
  useEffect
} from 'react'
import { User } from '../domain/user'
import {
  persistUserToLocalStorage,
  getUserFromLocalStorage,
  clearUserFromLocalStorage
} from '../utils/localStorage'

export interface AuthContextValue {
  loggedInUser: User | null
  tryLogin: (user: User) => void
  tryLogout: () => void
}

export const AuthContext = createContext<AuthContextValue>({
  loggedInUser: null,
  tryLogin: (_) => {},
  tryLogout: () => {}
})

export const AuthProvider: FunctionComponent = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null)

  useEffect(() => {
    setLoggedInUser(getUserFromLocalStorage())
  }, [])

  const handleLogin = (user: User): void => {
    setLoggedInUser(user)
    persistUserToLocalStorage(user)
  }

  const handleLogout = (): void => {
    setLoggedInUser(null)
    clearUserFromLocalStorage()
  }

  const contextValue: AuthContextValue = {
    loggedInUser,
    tryLogin: handleLogin,
    tryLogout: handleLogout
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}
