import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

interface RoleBadgeProps {
  classes?: string
}

export const RoleBadge: FunctionComponent<RoleBadgeProps> = ({ children, classes }) => {
  return (
    <span className={classnames('rounded-sm py-1 px-3 font-bold bg-gray-100 text-xs', classes)}>
      {children}
    </span>
  )
}
