import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '../components/Button'
import { Portal } from 'react-portal'
import { ReactComponent as MenuIcon } from '../assets/icons/list.svg'
import { ReactComponent as CloseIcon } from '../assets/icons/x.svg'
import { useAuth } from '../hooks/useAuth'

export const MobileHeader = (): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const { loggedInUser, tryLogout } = useAuth()
  const location = useLocation()

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return (
    <>
      <Portal>
        <div className={classnames('fixed bg-white top-0 left-0 w-full h-full p-4', { hidden: !isMenuOpen })}>
          <div className='flex flex-col'>
            <div className='flex flex-row items-center'>
              {loggedInUser !== null && (
                <div className='flex flex-1 flex-row items-center space-x-4'>
                  <div className='font-bold'>{loggedInUser.email}</div>
                  <Button onClick={tryLogout}>Log-off</Button>
                </div>
              )}
              <div className='ml-auto'>
                <button onClick={() => setIsMenuOpen(false)}>
                  <CloseIcon className='w-8 h-8' />
                </button>
              </div>
            </div>
            <div className='flex flex-col space-y-1 text-gray-600'>
              <Link to='/apps'>
                Apps
              </Link>
              <Link to='/users'>
                Users
              </Link>
            </div>
          </div>
        </div>
      </Portal>

      <header className='flex flex-row items-center px-4 py-2 border-b border-gray-100'>
        <h1 className='font-bold text-2xl'>iros-users</h1>

        <div className='ml-auto'>
          <button onClick={() => setIsMenuOpen(true)}>
            <MenuIcon className='w-5 h-5'/>
          </button>
        </div>
      </header>
    </>
  )
}
