import React, { FunctionComponent, ButtonHTMLAttributes } from 'react'
import classnames from 'classnames'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  classes?: string
}

export const Button: FunctionComponent<ButtonProps> = ({ children, classes, ...props }) => {
  return (
    <button
      className={classnames('rounded-md border border-gray-200 text-sm md:text-base', classes)}
      {...props}
    >
      <div className='bg-white px-4 py-2 bg-opacity-0 hover:bg-opacity-20 transition-colors'>
        {children}
      </div>
    </button>
  )
}
