import React, {
  FunctionComponent,
  createContext,
  useState,
  useEffect
} from 'react'
import { Message } from '../domain/message'
import { APIError } from '../services/APIError'

export interface MessageContextValue {
  message: Message | null
  addMessage: (msg: Message) => void
  handleAPIError: (err: APIError) => void
}

export const MessageContext = createContext<MessageContextValue>({
  message: null,
  addMessage: (_) => {},
  handleAPIError: (_) => {}
})

export const MessageProvider: FunctionComponent = ({ children }) => {
  const [message, setMessage] = useState<Message | null>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage(null)
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [message])

  const contextValue: MessageContextValue = {
    message,
    addMessage: setMessage,
    handleAPIError: ({ message }) => setMessage({
      type: 'ERROR',
      content: message
    })
  }

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  )
}
