import React, { useState, FunctionComponent } from 'react'
import classnames from 'classnames'
import { generateRandomPastelHex } from '../utils/color'

interface RoundedIconProps {
  colorful: boolean
}

export const RoundedIcon: FunctionComponent<RoundedIconProps> = ({ children, colorful }) => {
  const [color] = useState<null | string>(colorful ? generateRandomPastelHex() : null)

  return (
    <div
      className={classnames('flex items-center justify-center rounded-full w-14 h-14', {
        'bg-gray-200': !colorful
      })}
      style={{ backgroundColor: color ?? '' }}
    >
      {children}
    </div>
  )
}
