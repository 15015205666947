import React from 'react'

interface SidebarSelectionRowProps {
  name: string
  icon: JSX.Element
}

export const SidebarSelectionRow = ({ name, icon }: SidebarSelectionRowProps): JSX.Element => {
  return (
    <button className='flex flex-row w-full p-3 items-center text-gray-600 text-lg space-x-4 hover:text-black hover:bg-gray-100 rounded-md transition-colors'>
      <div>
        {icon}
      </div>
      <div>
        {name}
      </div>
    </button>
  )
}
