import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import { HashRouter } from 'react-router-dom'
import { MessageProvider } from './contexts/message'
import { AuthProvider } from './contexts/auth'
import { UsersContextProvider } from './contexts/users'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <MessageProvider>
      <AuthProvider>
        <UsersContextProvider>
          <HashRouter>
            <App />
          </HashRouter>
        </UsersContextProvider>
      </AuthProvider>
    </MessageProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
