/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const persistToLocalStorage = (name: string) => (data: object) => window.localStorage.setItem(name, JSON.stringify(data))

export const persistUserToLocalStorage = persistToLocalStorage('loggedInUser')

export const getFromLocalStorage = (name: string) => JSON.parse(window.localStorage.getItem(name) as string)

export const getUserFromLocalStorage = () => getFromLocalStorage('loggedInUser')

export const clearFromLocalStorage = (name: string) => window.localStorage.removeItem(name)

export const clearUserFromLocalStorage = () => clearFromLocalStorage('loggedInUser')
