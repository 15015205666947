import React from 'react'
import { RoleBadge } from './RoleBadge'

export const SuperAdminRoleBadge = (): JSX.Element => {
  return (
    <RoleBadge
      classes='bg-yellow-100 text-yellow-700'
    >
      super-admin
    </RoleBadge>
  )
}
