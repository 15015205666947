/* eslint-disable @typescript-eslint/promise-function-async */

import { TaskEither, tryCatch } from 'fp-ts/TaskEither'
import { APIError } from './APIError'
import { App } from '../domain/app'
import {
  getAuthHeader,
  getServerUrl,
  toAPIError
} from './utils'
import {
  axiosInstance,
  axiosPromise
} from './axios'

export const fetchApps: TaskEither<APIError, App[]> = tryCatch(
  () => axiosPromise(axiosInstance.get(
    `${getServerUrl()}/apps`,
    {
      headers: {
        ...getAuthHeader()
      }
    }
  )),
  toAPIError
)

export const fetchApp = (name: string): TaskEither<APIError, App> => tryCatch(
  () => axiosPromise(axiosInstance.get(
    `${getServerUrl()}/apps/`,
    {
      params: {
        name
      },
      headers: {
        ...getAuthHeader()
      }
    }
  )),
  toAPIError
)

export const addApp = (newApp: App): TaskEither<APIError, App> => tryCatch(
  () => axiosPromise(axiosInstance.post(
    `${getServerUrl()}/apps`,
    {
      newApp
    },
    {
      headers: {
        ...getAuthHeader()
      }
    }
  )),
  toAPIError
)

export const updateApp = (newApp: App): TaskEither<APIError, App> => tryCatch(
  () => axiosPromise(axiosInstance.post(
    `${getServerUrl()}/apps`,
    {
      newApp
    },
    {
      headers: {
        ...getAuthHeader()
      }
    }
  )),
  toAPIError
)
