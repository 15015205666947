import React, { FunctionComponent } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

interface ProtectedRouterProps extends RouteProps {}

export const ProtectedRouter: FunctionComponent<ProtectedRouterProps> = ({ children, ...rest }) => {
  const { loggedInUser } = useAuth()

  return (
    <Route
      {...rest}
      render={
        () => loggedInUser !== null
          ? children
          : <Redirect to='/login' />
      }
    />
  )
}
