import { getUserFromLocalStorage } from '../utils/localStorage'
import { User } from '../domain/user'
import { APIError } from './APIError'

export const handleInternalError = (): void => {
  throw new Error('Internal error. Server is not responsive.')
}

export const getToken = (): string => {
  const storedUser = getUserFromLocalStorage() as User
  const user = storedUser?.auth?.filter((auth) => auth.type === 'jwt')
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!user) {
    return ''
  }
  const { auth } = user[0]
  return auth
}

export const getAuthHeader = (): Record<string, string> => ({
  Authorization: `JWT ${getToken()}`
})

export const getServerUrl = (): string => process.env.REACT_APP_SERVER_URI as string

export const toAPIError = (data: unknown): APIError => data as APIError
